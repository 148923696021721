// extracted by mini-css-extract-plugin
export var additional = "SmallPlanCard-module--additional--6e17c";
export var alertRed = "SmallPlanCard-module--alert-red--36fa1";
export var benefitIcon = "SmallPlanCard-module--benefitIcon--30682";
export var benefitName = "SmallPlanCard-module--benefitName--a0438";
export var benefits = "SmallPlanCard-module--benefits--c4c8d";
export var bgBarGrey = "SmallPlanCard-module--bg-bar-grey--98248";
export var bgBeige = "SmallPlanCard-module--bg-beige--61c1e";
export var bgBeige2 = "SmallPlanCard-module--bg-beige2--5aae9";
export var bgCopper = "SmallPlanCard-module--bg-copper--2dd51";
export var bgDaffodil = "SmallPlanCard-module--bg-daffodil--7aac7";
export var bgGreyText = "SmallPlanCard-module--bg-grey-text--d5bcd";
export var bgMing = "SmallPlanCard-module--bg-ming--7e09c";
export var bgMint = "SmallPlanCard-module--bg-mint--dd009";
export var bgRed = "SmallPlanCard-module--bg-red--d4ed8";
export var bgRedTrans = "SmallPlanCard-module--bg-red-trans--c3e2e";
export var bgSand = "SmallPlanCard-module--bg-sand--68bdf";
export var bgSoftSun = "SmallPlanCard-module--bg-softSun--acbc8";
export var bgTeal = "SmallPlanCard-module--bg-teal--34516";
export var bgWhite = "SmallPlanCard-module--bg-white--d278e";
export var bgWomenSharp = "SmallPlanCard-module--bg-women-sharp--df75e";
export var blackText = "SmallPlanCard-module--black-text--e40e7";
export var borderMing = "SmallPlanCard-module--border-ming--b1ab8";
export var copper = "SmallPlanCard-module--copper--4d104";
export var corners = "SmallPlanCard-module--corners--8c744";
export var cursorNormal = "SmallPlanCard-module--cursor-normal--59a39";
export var cursorPointer = "SmallPlanCard-module--cursor-pointer--62cb1";
export var customMarkdown = "SmallPlanCard-module--customMarkdown--06cc6";
export var dUnset = "SmallPlanCard-module--d-unset--87cdf";
export var darkGrey = "SmallPlanCard-module--dark-grey--064c4";
export var dash = "SmallPlanCard-module--dash--95d18";
export var dashBig = "SmallPlanCard-module--dashBig--c1f6c";
export var deadSalmon = "SmallPlanCard-module--dead-salmon--9c9d4";
export var flex = "SmallPlanCard-module--flex--c2265";
export var flex1 = "SmallPlanCard-module--flex-1--cba14";
export var fontSize12 = "SmallPlanCard-module--font-size-12--69326";
export var fontSize20 = "SmallPlanCard-module--font-size-20--918d9";
export var greyPlaceholder = "SmallPlanCard-module--grey-placeholder--1aca3";
export var greyText = "SmallPlanCard-module--grey-text--1d021";
export var h1sizing = "SmallPlanCard-module--h1sizing--6ee0e";
export var h2sizing = "SmallPlanCard-module--h2sizing--c4250";
export var hidden = "SmallPlanCard-module--hidden--77105";
export var imageAni = "SmallPlanCard-module--imageAni--ee767";
export var imageZoom = "SmallPlanCard-module--imageZoom--8a246";
export var imageZoomGatsby = "SmallPlanCard-module--imageZoomGatsby--83d95";
export var include = "SmallPlanCard-module--include--e7054";
export var italic = "SmallPlanCard-module--italic--b8876";
export var lightGrey = "SmallPlanCard-module--light-grey--f72cd";
export var logo = "SmallPlanCard-module--logo--d4369";
export var logoWrapper = "SmallPlanCard-module--logoWrapper--44830";
export var lora = "SmallPlanCard-module--lora--6046d";
export var loraBold = "SmallPlanCard-module--lora-Bold--ddf11";
export var loraBoldItalic = "SmallPlanCard-module--lora-BoldItalic--0c953";
export var loraMedium = "SmallPlanCard-module--lora-Medium--53bef";
export var loraSemiBold = "SmallPlanCard-module--lora-SemiBold--088a5";
export var main = "SmallPlanCard-module--main--18b18";
export var ming = "SmallPlanCard-module--ming--323c6";
export var mingHover2 = "SmallPlanCard-module--ming-hover-2--85620";
export var modalOpen = "SmallPlanCard-module--modal-open--dc606";
export var noUnderline = "SmallPlanCard-module--no-underline--225ea";
export var openSans = "SmallPlanCard-module--openSans--eb365";
export var openSansBold = "SmallPlanCard-module--openSans-Bold--a8973";
export var openSansSemiBold = "SmallPlanCard-module--openSans-SemiBold--a22f6";
export var pageGuide = "SmallPlanCard-module--page-guide--f521a";
export var planName = "SmallPlanCard-module--planName--104a5";
export var popUp = "SmallPlanCard-module--popUp--7cfb0";
export var popUpAni = "SmallPlanCard-module--pop-up-ani--56cae";
export var premiums = "SmallPlanCard-module--premiums--ac2c3";
export var price = "SmallPlanCard-module--price--9da1c";
export var quotation = "SmallPlanCard-module--quotation--141ae";
export var round = "SmallPlanCard-module--round--6245c";
export var roundCorners = "SmallPlanCard-module--round-corners--2833d";
export var smallCardPlan = "SmallPlanCard-module--smallCardPlan--2408c";
export var smallCardPlanInner = "SmallPlanCard-module--smallCardPlanInner--579fb";
export var softCorners = "SmallPlanCard-module--soft-corners--697b2";
export var softShadows = "SmallPlanCard-module--soft-shadows--a07b8";
export var softerCorners = "SmallPlanCard-module--softer-corners--7255b";
export var softyCorners = "SmallPlanCard-module--softy-corners--7c6bb";
export var tableShadow = "SmallPlanCard-module--table-shadow--13b62";
export var teal = "SmallPlanCard-module--teal--f5625";
export var title = "SmallPlanCard-module--title--5b0b6";
export var topNavShadow = "SmallPlanCard-module--top-nav-shadow--ef092";
export var topicArea = "SmallPlanCard-module--topicArea--e8ae2";
export var uppercase = "SmallPlanCard-module--uppercase--e7113";
export var wFull = "SmallPlanCard-module--w-full--f1fd8";
export var white = "SmallPlanCard-module--white--12d7d";
export var womenRed = "SmallPlanCard-module--women-red--91789";
export var womenSharp = "SmallPlanCard-module--women-sharp--37ebf";