// extracted by mini-css-extract-plugin
export var alertRed = "BigPlanCard-module--alert-red--b1b09";
export var applyBtn = "BigPlanCard-module--applyBtn--2a5f4";
export var benefit = "BigPlanCard-module--benefit--10adc";
export var benefitDetails = "BigPlanCard-module--benefitDetails--1a243";
export var benefitIcon = "BigPlanCard-module--benefitIcon--8bcf3";
export var benefitIcon2 = "BigPlanCard-module--benefitIcon2--9b60f";
export var benefitList = "BigPlanCard-module--benefitList--75da7";
export var benefitRow = "BigPlanCard-module--benefitRow--c4952";
export var bgBarGrey = "BigPlanCard-module--bg-bar-grey--e83e7";
export var bgBeige = "BigPlanCard-module--bg-beige--e3787";
export var bgBeige2 = "BigPlanCard-module--bg-beige2--af9d0";
export var bgCopper = "BigPlanCard-module--bg-copper--c59d7";
export var bgDaffodil = "BigPlanCard-module--bg-daffodil--7ca89";
export var bgGreyText = "BigPlanCard-module--bg-grey-text--46e30";
export var bgMing = "BigPlanCard-module--bg-ming--dd418";
export var bgMint = "BigPlanCard-module--bg-mint--b0761";
export var bgRed = "BigPlanCard-module--bg-red--568ce";
export var bgRedTrans = "BigPlanCard-module--bg-red-trans--a4f48";
export var bgSand = "BigPlanCard-module--bg-sand--0c82e";
export var bgSoftSun = "BigPlanCard-module--bg-softSun--10ed1";
export var bgTeal = "BigPlanCard-module--bg-teal--2e26d";
export var bgWhite = "BigPlanCard-module--bg-white--7800f";
export var bgWomenSharp = "BigPlanCard-module--bg-women-sharp--f733c";
export var bigCardPlan = "BigPlanCard-module--bigCardPlan--f55b5";
export var bigcardCenter = "BigPlanCard-module--bigcardCenter--d06f9";
export var bigcardRight = "BigPlanCard-module--bigcardRight--0a34e";
export var blackText = "BigPlanCard-module--black-text--2267f";
export var borderMing = "BigPlanCard-module--border-ming--13343";
export var checkbox = "BigPlanCard-module--checkbox--ec648";
export var checked = "BigPlanCard-module--checked--4f195";
export var copper = "BigPlanCard-module--copper--c24f6";
export var corners = "BigPlanCard-module--corners--f68aa";
export var cursorNormal = "BigPlanCard-module--cursor-normal--05750";
export var cursorPointer = "BigPlanCard-module--cursor-pointer--9edf1";
export var customMarkdown = "BigPlanCard-module--customMarkdown--1b7f4";
export var dUnset = "BigPlanCard-module--d-unset--10803";
export var darkGrey = "BigPlanCard-module--dark-grey--9fe8a";
export var dash = "BigPlanCard-module--dash--82db1";
export var dashBig = "BigPlanCard-module--dashBig--0aec7";
export var deadSalmon = "BigPlanCard-module--dead-salmon--4f81f";
export var flex = "BigPlanCard-module--flex--afd76";
export var flex1 = "BigPlanCard-module--flex-1--c9b1a";
export var fontSize12 = "BigPlanCard-module--font-size-12--d8401";
export var fontSize20 = "BigPlanCard-module--font-size-20--326ab";
export var greyPlaceholder = "BigPlanCard-module--grey-placeholder--2a006";
export var greyText = "BigPlanCard-module--grey-text--92670";
export var h1sizing = "BigPlanCard-module--h1sizing--21e95";
export var h2sizing = "BigPlanCard-module--h2sizing--e1d69";
export var hidden = "BigPlanCard-module--hidden--1c203";
export var imageAni = "BigPlanCard-module--imageAni--6bd46";
export var imageZoom = "BigPlanCard-module--imageZoom--8f6e5";
export var imageZoomGatsby = "BigPlanCard-module--imageZoomGatsby--d80b1";
export var infoRow = "BigPlanCard-module--infoRow--ebae4";
export var italic = "BigPlanCard-module--italic--1e69d";
export var leftUpper = "BigPlanCard-module--leftUpper--0b244";
export var lightGrey = "BigPlanCard-module--light-grey--df43a";
export var logo = "BigPlanCard-module--logo--297ae";
export var logoWrapper = "BigPlanCard-module--logoWrapper--3b3b4";
export var lora = "BigPlanCard-module--lora--7576b";
export var loraBold = "BigPlanCard-module--lora-Bold--79e6b";
export var loraBoldItalic = "BigPlanCard-module--lora-BoldItalic--45830";
export var loraMedium = "BigPlanCard-module--lora-Medium--17bf9";
export var loraSemiBold = "BigPlanCard-module--lora-SemiBold--ab68c";
export var main = "BigPlanCard-module--main--bdfe0";
export var ming = "BigPlanCard-module--ming--d7d14";
export var mingHover2 = "BigPlanCard-module--ming-hover-2--9df33";
export var modalOpen = "BigPlanCard-module--modal-open--4add1";
export var noUnderline = "BigPlanCard-module--no-underline--695f4";
export var openSans = "BigPlanCard-module--openSans--7a875";
export var openSansBold = "BigPlanCard-module--openSans-Bold--1e378";
export var openSansSemiBold = "BigPlanCard-module--openSans-SemiBold--23742";
export var pageGuide = "BigPlanCard-module--page-guide--23c90";
export var planName = "BigPlanCard-module--planName--1af3c";
export var popUp = "BigPlanCard-module--popUp--d1426";
export var popUpAni = "BigPlanCard-module--pop-up-ani--936ca";
export var premium = "BigPlanCard-module--premium--5e09f";
export var price = "BigPlanCard-module--price--9acd2";
export var priceInfo = "BigPlanCard-module--priceInfo--7b506";
export var quotation = "BigPlanCard-module--quotation--459a4";
export var round = "BigPlanCard-module--round--a5ee5";
export var roundCorners = "BigPlanCard-module--round-corners--f005a";
export var softCorners = "BigPlanCard-module--soft-corners--e9a45";
export var softShadows = "BigPlanCard-module--soft-shadows--6e08f";
export var softerCorners = "BigPlanCard-module--softer-corners--9b9e2";
export var softyCorners = "BigPlanCard-module--softy-corners--14dc2";
export var tableShadow = "BigPlanCard-module--table-shadow--7a5ae";
export var teal = "BigPlanCard-module--teal--c5294";
export var topNavShadow = "BigPlanCard-module--top-nav-shadow--e0ff7";
export var topicArea = "BigPlanCard-module--topicArea--3ff23";
export var uncheckable = "BigPlanCard-module--uncheckable--9ee26";
export var uncheckableBox = "BigPlanCard-module--uncheckableBox--4f7b6";
export var uppercase = "BigPlanCard-module--uppercase--809be";
export var wFull = "BigPlanCard-module--w-full--eff81";
export var white = "BigPlanCard-module--white--44c32";
export var womenRed = "BigPlanCard-module--women-red--40422";
export var womenSharp = "BigPlanCard-module--women-sharp--16920";