import * as style from "./search.module.scss"

import React, { useState, useEffect, useRef } from "react"
import { openPopupWidget } from "react-calendly"
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image"
import {
  getAllLocales,
  getCurrentLanguagePage,
  getAge,
  getCountryList,
  updateUrlByLocale,
  getLocalStorage,
  getGender,
  // hasAllInList,
} from "../../plugins/common"
import { createEqtLog, updateEqtLog } from "./hooks"
import { staticText } from "../../enumerator/staticText"
import { pipedriveValue } from "../../enumerator/pipedriveValue"
import Layout from "../../components/Layout"
import glossaryHeaderBg from "../../images/glossary/bg.webp"
import StepThankyou from "../../components/quotation/Thankyou"
import StepSearch from "../../components/quotation/Search"
import Dash from "../../components/Dash"
import QuotationApply from "../../components/quotation/Apply"
import { quotationType } from "../../enumerator/quotationType"
import { gender } from "../../enumerator/gender"
import {
  createBenefit,
  createBudget,
  createInsuranceCompany,
  createMandrillBody,
  createPlan,
  createPipedriveBody,
  createStrapiBody,
  createPolicyReqBody,
} from "../../DTO/eqtDTO"
import {
  getPlans,
  createPipedriveEntry,
  sendEmail,
  createStrapiEntry,
} from "../../DAO/eqtDAO"
import SnackBar from "../../components/SnackBar"
import Loading from "../../components/Loading"
import { convert } from "../../plugins/currency"
import { updateMeta } from "../../plugins/quotation"
import ToolBottom from "../../components/quotation/ToolBottom"
import { navigate } from "gatsby-link"
import Skeleton from "../../components/Skeleton"

// import ExplainSection from "../../components/quotation/ExplainSection"
// import DetailsCard from "../../components/quotation/DetailsCard"
// import MobileFilter from "../../components/quotation/MobileFilter"
const ExplainSection = loadable(() =>
  import("../../components/quotation/ExplainSection")
)
const DetailsCard = loadable(() =>
  import("../../components/quotation/DetailsCard")
)
const MobileFilter = loadable(() =>
  import("../../components/quotation/MobileFilter")
)
// const Banner = loadable(() => import("../../components/Banner"))
const Resources = loadable(() => import("../../components/homepage/Resources"))
// const Pros = loadable(() => import("../../components/homepage/Pros"))
// const Process = loadable(() => import("../../components/homepage/Process"))
// const Partners = loadable(() => import("../../components/homepage/Partners"))
// const Testimonial = loadable(() =>
//   import("../../components/homepage/Testimonial")
// )

const leftEmpty = "../../images/quotation/left-empty.png"
const leftFull = "../../images/quotation/left-full.png"
const shape_right = "../../images/quotation/right-shapes.png"

const PLANS_PER_PAGE = 6
const QuotationSearchPage = ({ pageContext, location }) => {
  const {
    // title,
    meta,
    localizations,
    locale,
  } = pageContext.node

  const data = pageContext.node
  // console.log("data:   : ", data)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...pageContext.form?.freeQuote,
  }
  // const homepageData = pageContext.homepage[0]
  // const generalData = pageContext.general.allStrapiGeneral.nodes[0]
  const currencies = pageContext.currencies

  const keyLabels = pageContext.general.allStrapiRessource.nodes[0]
  const languageOptions = pageContext.general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const iqtToken = pageContext.general.iqtToken
  const aleaToken = pageContext.general.aleaToken
  // let headerTitle = data.headerTitle
  const searchTitle = data.search.title
  const searchSubTitle = data.search.subtitle
  const applyTitle = data.applyFlow.title
  const thankyouTitle = data.thankYouTitle
  const comapreTitle = data.compareFlow.title
  const learnMoreTitle = data.receiveDetailsFlow.title
  const applyThankSubtitle = data.applyFlow.thankYouSubtitle
  const applyThankContent = data.applyFlow.thankYouContent
  const compareThankSubtitle = data.compareFlow.thankYouSubtitle
  const compareThankContent = data.compareFlow.thankYouContent
  const recieveThankSubtitle = data.receiveDetailsFlow.thankYouSubtitle
  const recieveThankContent = data.receiveDetailsFlow.thankYouContent
  const explainTitle = data.expectation.title
  const explainContent = data.expectation.content
  // const bookACallCta = data.mobileFilter.bookACallCta
  const dynamicMetaTitle = data.dynamicMetaTitle
  const dynamicMetaDescription = data.dynamicMetaDescription
  const dynamicHeaderTitle = data.dynamicHeaderTitle
  const serverErrorMsg = data.search.serverErrorMsg
  const underageWarning = data.search.underageWarning
  const uncheckInpatientWarning = data.search.uncheckInpatientWarning
  const toolBottomCta = data.toolBottomCta
  const searchLabels = {
    ...data.search,
    ...data.compareBar,
    invalidEmailMsg: data.stepEmail.invalidEmailMsg,
    emailPh: data.stepEmail.emailPlaceholder,
    emailTnc: data.stepEmail.emailTnc,
    invalidTncMsg: data.stepEmail.invalidTncMsg,
    resultEmailTitle: data.stepEmail.title,
    resultEmailSubtitle: data.stepEmail.subtitle,
    submitLabel: data.submitLabel,
    // perMonthLabel: "/Month",
    // moreDetailsLabel: "More Details",
  }
  const applyLabels = {
    comparisonTable: data.comparisonTable,
    formLabels: data.form,
    additionalOptionsLabel: data.search.additionalOptionsLabel,
    backLabel: data.backLabel,
    submitLabel: data.submitLabel,
    applyNowLabel: data.applyNowLabel,
    applySubtitle: data.applyFlow.subtitle,
    compareSubtitle: data.compareFlow.subtitle,
    recieveSubtitle: data.receiveDetailsFlow.subtitle,
    usdLabel: data.search.usdLabel,
    perMonthLabel: data.search.perMonthLabel,
    perYearLabel: data.search.perYearLabel,
  }

  const budgetCurrencies = data.budget.map(b => b.currency?.name)
  const defaultCurrency = data.defaultCurrency
  const availableCurrencies = data.availableCurrencies
  const currencyList = [defaultCurrency, ...availableCurrencies].filter(c =>
    budgetCurrencies.includes(c.name)
  )

  const defaultOptions = {
    gender: gender.female,
    benefitIds: [],
    age: null,
  }

  const savedInformation =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("searchOptions"))
      : null
  const hideAgeFilter = useRef(false)
  let savedAdditionalInsurers = []
  let defaultBirthday = {}
  let defaultGender = gender.female
  let defaultAge = data.age.defaultAge ?? 35
  const ageList = Array.from(Array(83).keys(), n => n + 18)
  const countryList = getCountryList(lang)
  const goToQuote = () => navigate(updateUrlByLocale("/get-a-quote", lang))
  if (savedInformation) {
    defaultOptions.benefitIds = savedInformation.benefitIds
    defaultOptions.gender = savedInformation.insurerDetails
      ?.map(p => p.gender)
      .join(";")
    defaultOptions.age = savedInformation.insurerDetails
      ?.map(p => getAge(p.dd, p.mm, p.yyyy))
      .join(";")

    // console.log("savedInformation: ", savedInformation)

    savedAdditionalInsurers = savedInformation.insurerDetails
      .map((p, index) => {
        if (index === 0) {
          defaultGender = p.gender
          defaultAge = getAge(p.dd, p.mm, p.yyyy)
          defaultBirthday = { dd: p.dd, mm: p.mm, yyyy: p.yyyy }
          return null
        }
        return {
          ...p,
          age: getAge(p.dd, p.mm, p.yyyy),
        }
      })
      .filter(f => f)
    hideAgeFilter.current = true
  }

  const quotationForm = {
    firstName: "",
    familyName: "",
    countryCode: "+852",
    phone: "",
    email: "",
  }

  //insurance company
  const insuranceCompany = data.insuranceCompany.insuranceCompanyValue
  let insuranceCompanyList = insuranceCompany
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(item => createInsuranceCompany(item))

  //budget
  const getBudgetObj = (budgetData, currentCurrency) =>
    budgetData.find(b => b.currency?.name === currentCurrency.name) ??
    budgetData[0]
  const createBudgetFilter = (budgetData, currentCurrency) => {
    const budget = getBudgetObj(budgetData, currentCurrency)
    const budgets = budget.budgetValue
    const budgetList = budgets.map(item => createBudget(item))

    return budgetList
  }
  const budget = getBudgetObj(data.budget, defaultCurrency)
  const defaultBudgetTitle = budget.title
  const budgetList = createBudgetFilter(data.budget, defaultCurrency)

  //benefit
  const benefits = data.benefits.benefitsValue
  let benefitList = benefits.map(item =>
    createBenefit(item, defaultOptions.benefitIds)
  )

  const defaultCfe = {
    name: "CFE",
    isSelected: false,
  }

  const [step, setStep] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [selectedPlans, setSelectedPlans] = useState([])
  const [headerTitle, setHeaderTitle] = useState(data.headerTitle)
  const [progressTitle, setProgressTitle] = useState(searchTitle)
  const [thankSubtitle, setThankSubtitle] = useState(applyThankSubtitle)
  const [thankContent, setThankContent] = useState(applyThankContent)
  const [form, setForm] = useState(quotationForm)
  const [applyType, setApplyType] = useState(null)
  const [email, setEmail] = useState("")
  const [pdPerson, setPdPerson] = useState(null)
  const [pdLead, setPdLead] = useState(null)
  const [hasEmail, setHasEmail] = useState(true)
  const [initialized, setInitialized] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")
  const [insuranceCompanyFilter, setInsuranceCompanyFilter] =
    useState(insuranceCompanyList)
  const [budgetFilter, setBudgetFilter] = useState(budgetList)
  const [benefitsFilter, setBenefitsFilter] = useState(benefitList)
  const [cfeFilter, setCfeFilter] = useState([defaultCfe])
  const [age, setAge] = useState(defaultAge)
  const [mainGender, setMainGender] = useState(defaultGender)
  const [mainBirthday, setMainBirthday] = useState(defaultBirthday)
  const [plansSearched, setPlanSearched] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [sort, setSort] = useState(1)
  const [premiumDivideBy, setPremiumDivideBy] = useState(12)
  const [limit, setLimit] = useState(0)
  const [currency, setCurrency] = useState(defaultCurrency)
  const [budgetTitle, setBudgetTitle] = useState(defaultBudgetTitle)

  const [query, setQuery] = useState(null)

  const [plansForComparing, setPlansForComparing] = useState([])
  const [additionInsurers, setAdditionInsurers] = useState(
    savedAdditionalInsurers
  )

  const [detaliCard, setDetaliCard] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)

  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")
  const [noMetaIndex, setNoMetaIndex] = useState(false)

  const filterLabels = {
    insuranceCompanyLabel: data.insuranceCompany.title,
    budgetLabel: budgetTitle,
    benefitsLabel: data.benefits.title,
    yourAgeLabel: data.age.title,
    sortLabel: data.mobileFilter.sortLabel,
    filterLabel: data.mobileFilter.filterLabel,
    applyFilterLabel: data.mobileFilter.applyFilterLabel,
    currencyLabel: data.mobileFilter.currencyLabel,
    priceLabel: data.mobileFilter.priceLabel,
    lowToHigh: data.mobileFilter.lowToHigh,
    highToLow: data.mobileFilter.highToLow,
    seeMoreLabel: data.search.seeMoreLabel,
    seeLessLabel: data.search.seeLessLabel,
    genderLabel: data.mobileFilter.genderLabel,
    addPersonLabel: data.mobileFilter.addPersonLabel,
    addPersonTitle: data.mobileFilter.addPersonTitle,
    locationLabel: data.mobileFilter.locationLabel,
    monthlyLabel: data.mobileFilter.monthlyLabel,
    yearlyLabel: data.mobileFilter.yearlyLabel,
    hongKongLabel: data.mobileFilter.hongKongLabel,
    ageLabel: data.mobileFilter.ageLabel,
    maleLabel: data.mobileFilter.maleLabel,
    femaleLabel: data.mobileFilter.femaleLabel,
    allLabel: data.mobileFilter.allLabel,
    selectedLabel: data.mobileFilter.selectedLabel,
  }

  const filterGetter = {
    email: email,
    hasEmail: hasEmail,
    insuranceCompany: insuranceCompanyFilter,
    budget: budgetFilter,
    benefits: benefitsFilter,
    cfe: cfeFilter,
    age: age,
    sort: sort,
    limit: limit,
    mainGender: mainGender,
    premiumDivideBy: premiumDivideBy,
    additionInsurers: additionInsurers,
    errorMessage: errorMessage,
  }
  const filterSetter = {
    setEmail: setEmail,
    setInsuranceCompany: setInsuranceCompanyFilter,
    setBenefits: setBenefitsFilter,
    setCfe: setCfeFilter,
    setAge: setAge,
    setSort: setSort,
    setLimit: setLimit,
    setMainGender: setMainGender,
    setPremiumDivideBy: setPremiumDivideBy,
    setAdditionInsurers: setAdditionInsurers,
    setErrorMessage: setErrorMessage,
  }
  const openCalendly = () =>
    openPopupWidget({ url: data.compareBar.getAdviceCta.url })
  // console.log(data.compareBar)

  const gatherIds = list =>
    list?.filter(ele => ele.isSelected).map(ele => parseInt(ele.id))

  const gatherNames = list =>
    list.filter(ele => ele.isSelected).map(ele => ele.name)

  const gatherAnonymous = list =>
    list.map((ele, index) => {
      return `Person${index + 1} | Age:${ele.age}, Gender:${getGender(
        ele.gender
      )}\n`
    })

  const createLeadNotes = () =>
    "Benefits: \n" +
    gatherNames(benefitsFilter).join(", ") +
    "\n\nCompanies: \n" +
    gatherNames(insuranceCompanyFilter).join(", ") +
    "\n\nAdditional insurers: \n" +
    gatherAnonymous(additionInsurers).join("")

  const loadPlans = async (page, disableLoading) => {
    if (!disableLoading) setLoading(true)
    const benefitIds = gatherIds(benefitsFilter)
    const companyIds = gatherIds(insuranceCompanyFilter)
    let minBudget = Math.min(
      ...budgetFilter.filter(ele => ele.isSelected).map(ele => ele.minValue)
    )
    let maxBudget = Math.max(
      ...budgetFilter.filter(ele => ele.isSelected).map(ele => ele.maxValue)
    )

    let ages = additionInsurers.map(a => a.age)
    let genders = additionInsurers.map(a => a.gender)
    ages.unshift(age)
    genders.unshift(mainGender)
    // const gender =
    //   typeof defaultOptions.gender === "string"
    //     ? defaultOptions.gender.split(";")
    //     : [defaultOptions.gender]
    // const ages =
    //   typeof defaultOptions.age === "string"
    //     ? defaultOptions.age.split(";")
    //     : defaultOptions.age ?? [parseInt(age)]

    // defaultOptions.age.split(";") ?? [age]

    if (ages.length === 1 && ages[0] < 18) {
      updateSnackBar(underageWarning)
    }

    if (
      !benefitIds.length ||
      !companyIds.length ||
      (minBudget !== 0 && !minBudget) ||
      !isFinite(minBudget) ||
      (maxBudget !== 0 && !maxBudget) ||
      !isFinite(maxBudget) ||
      (ages.length === 1 && !ages[0]) ||
      (ages.length === 1 && parseInt(ages[0]) < 18)
    ) {
      setLoading(false)
      return "cancelled"
    }

    minBudget = convert(
      minBudget,
      currency.name,
      lang === "fr" ? "EUR" : "USD",
      currencies
    )
    maxBudget = convert(
      maxBudget,
      currency.name,
      lang === "fr" ? "EUR" : "USD",
      currencies
    )
    const cfe = lang === "fr" ? cfeFilter[0].isSelected : false

    const body = createPolicyReqBody(
      benefitIds,
      minBudget,
      maxBudget,
      ages,
      genders,
      companyIds,
      page,
      sort > 0 ? 1 : 0,
      lang,
      cfe
    )

    const plans = await getPlans(body, iqtToken, query, setQuery)
    setLoading(false)
    if (plans === "cancelled") return "cancelled"
    const newPlans = plans.map(plan =>
      createPlan(
        plan,
        insuranceCompanyList,
        benefitList,
        data.search,
        lang,
        currency,
        currencies
      )
    )
    return newPlans
  }

  //--------------------META filter------------------//

  // update metadata, h1, filter from params
  const searchParams = decodeURI(location.search?.replace("?", ""))
  // let noindex = false
  useEffect(() => {
    updateMeta(
      searchParams,
      meta,
      setHeaderTitle,
      insuranceCompanyList,
      setInsuranceCompanyFilter,
      benefitList,
      setBenefitsFilter,
      noMetaIndex,
      setNoMetaIndex,
      dynamicMetaTitle,
      dynamicMetaDescription,
      dynamicHeaderTitle
    )
  }, [])

  //--------------------Update search------------------//
  useEffect(async () => {
    const updatePlans = async () => {
      setLoading(true)
      const oldLimit = limit
      const initLimit = 0
      setLimit(initLimit)
      const initPlans = await loadPlans(initLimit)
      if (initPlans === "cancelled") {
        setLimit(oldLimit)
        setLoading(false)
        return
      }

      await updateEqtLog(
        4,
        aleaToken,
        {
          pdPerson,
          pdLead,
          age: age,
          gender: mainGender,
          selectedBenefits: gatherIds(benefitsFilter),
          notes: createLeadNotes(),
          additionInsurers,
          companyIds: gatherIds(insuranceCompanyFilter),
        },
        { setPdPerson, setPdLead }
      )

      setLimit(6)
      setPlanSearched(initPlans)
      setPlansForComparing([])
      setLoading(false)

      const newPlans = await loadPlans(initLimit + PLANS_PER_PAGE, true)
      if (newPlans === "cancelled") return
      if (initPlans && newPlans) {
        const appenedPlans = [...initPlans, ...newPlans]
        setPlanSearched(appenedPlans)
      }
    }

    if (hasEmail) await updatePlans()
  }, [
    insuranceCompanyFilter,
    budgetFilter,
    benefitsFilter,
    cfeFilter,
    age,
    sort,
    currency,
    additionInsurers,
    mainGender,
    hasEmail,
  ])

  // useEffect(() => {
  //   const budget = getBudgetObj(data.budget, currency)
  //   const budgetTitle = budget.title
  //   const budgetList = createBudgetFilter(data.budget, currency)
  //   setBudgetFilter(budgetList)
  //   setBudgetTitle(budgetTitle)
  // }, [currency])

  const toggleModal = () => {
    if (typeof window === "undefined" || !window.document) {
      return
    }
    if (!openModal) document.body.classList.add("modal-open")
    else document.body.classList.remove("modal-open")
    setOpenModal(!openModal)
  }
  const proceedFurther = (plans, action) => {
    if (action === quotationType.APPLY) {
      setSelectedPlans([plans])
      setProgressTitle(applyTitle)
      setThankSubtitle(applyThankSubtitle)
      setThankContent(applyThankContent)
    } else if (action === quotationType.COMPARE) {
      setSelectedPlans(plans)
      setProgressTitle(comapreTitle)
      setThankSubtitle(compareThankSubtitle)
      setThankContent(compareThankContent)
    } else if (action === quotationType.RECIEVE_DETAILS) {
      setSelectedPlans([plans])
      setProgressTitle(learnMoreTitle)
      setThankSubtitle(recieveThankSubtitle)
      setThankContent(recieveThankContent)
    }
    setApplyType(action)
    setStep(2)
  }
  const updateStep = newStep => {
    if (newStep === 1) setProgressTitle(searchTitle)
    setStep(newStep)
  }

  const loadMorePlans = async () => {
    // +2 becoz limit hasn't updated yet
    const nextPage = limit + PLANS_PER_PAGE
    setLimit(limit + PLANS_PER_PAGE)
    const newPlans = await loadPlans(nextPage, true)
    if (newPlans) {
      const appenedPlans = [...plansSearched, ...newPlans]
      setPlanSearched(appenedPlans)
    }
  }

  const submitEmail = async () => {
    setSubmitLoading(true)
    const personData = { age: age, gender: mainGender }
    const leadData = { benefitIds: gatherIds(benefitsFilter) }
    const note = createLeadNotes()
    const companyIds = gatherIds(insuranceCompanyFilter)
    await createEqtLog(
      aleaToken,
      lang,
      { email, personData, leadData, note, additionInsurers, companyIds },
      { setPdPerson, setPdLead }
    )

    const updateForm = { ...form }
    updateForm.email = email
    setForm(updateForm)
    setSubmitLoading(false)
    setHasEmail(true)
  }

  const submitForm = async (images, policyIds) => {
    // const allInsurers = savedInformation?.insurerDetails
    // const clientInfo = allInsurers && allInsurers.length ? allInsurers[0] : null

    // const otherInsurers = additionInsurers;
    const clientInfo = {
      gender: mainGender,
      ...mainBirthday,
    }
    // allInsurers && allInsurers.length
    //   ? allInsurers.filter((ele, i) => i !== 0)
    //   : null

    const benefitIds = benefitsFilter
      .filter(ele => ele.isSelected)
      .map(ele => parseInt(ele.id))

    const maxBudget = Math.max(
      ...budgetFilter.filter(ele => ele.isSelected).map(ele => ele.maxValue)
    )

    const note =
      applyType === 0
        ? staticText.APPLY_NOTE
        : applyType === 1
        ? staticText.RECIEVE_DETAILS_NOTE
        : staticText.COMPARE_NOTE

    const type =
      applyType === 0
        ? staticText.APPLY
        : applyType === 1
        ? staticText.RECIEVE_DETAILS
        : staticText.COMPARE

    const source =
      applyType === 0
        ? [pipedriveValue.EQT_APPLY]
        : applyType === 1
        ? [pipedriveValue.EQT_RECEIVE_DETAILS]
        : [pipedriveValue.EQT_COMPARE]

    const body = createPipedriveBody(
      form,
      clientInfo,
      additionInsurers,
      age,
      staticText.HONGKONG,
      benefitIds,
      maxBudget,
      images,
      note,
      type,
      source,
      lang,
      policyIds
    )

    const strapiRes = await createStrapiEntry(
      aleaToken,
      createStrapiBody(type, body)
    )
    // console.log("strapiRes: ", strapiRes)
    const pipedriveRes = await createPipedriveEntry(aleaToken, body)
    // console.log("pipedriveRes: ", pipedriveRes)
    const emailRes = await sendEmail(
      aleaToken,
      createMandrillBody(form, images, lang)
    )
    // console.log("emailRes: ", emailRes)
    // setProgressTitle(thankyouTitle)
    // setStep(3)
    setSubmitLoading(false)
    if ((pipedriveRes || strapiRes) && emailRes) {
      setErrorMessage("")
      setProgressTitle(thankyouTitle)
      setStep(3)
    } else {
      console.log("submit form error")
      setErrorMessage(serverErrorMsg)
    }
  }

  const updateCompare = plan => {
    const i = plansForComparing.findIndex(p => p.id === plan.id)
    if (i >= 0) {
      const newComparePlans = plansForComparing
      newComparePlans.splice(i, 1)
      setPlansForComparing([...newComparePlans])
    } else if (plansForComparing.length >= 3) return
    else setPlansForComparing([...plansForComparing, plan])
  }

  const updateDetailCard = plan => {
    toggleModal()
    setDetaliCard(plan)
  }

  const updateMobileFilter = () => {
    toggleModal()
    setOpenFilter(!openFilter)
  }

  useEffect(() => {
    // const personItem = getLocalStorage("pdperson")
    // const leadItem = getLocalStorage("pdlead")
    // if (personItem?.email){
    //   setEmail(personItem.email)

    // }
    // if (personItem) setPdPerson(personItem)
    // if (leadItem) setPdLead(leadItem)

    const initEqtLogging = async userEmail => {
      if (!userEmail || !userEmail.length) return
      setSubmitLoading(true)
      await createEqtLog(
        aleaToken,
        lang,
        { email: userEmail },
        { setPdPerson, setPdLead }
      )

      const updateForm = { ...form }
      updateForm.email = userEmail

      setForm(updateForm)
      setSubmitLoading(false)
      // setHasEmail(true)
    }
    const person = getLocalStorage("pdperson")
    const lead = getLocalStorage("pdlead")
    const additionalMembers = getLocalStorage("pdmembers")
    const companies = getLocalStorage("pdcompanies")

    //apply stored data to filters
    if (person?.email) {
      setHasEmail(true)
      const { email, birthday, age, gender: genderValue } = person
      setEmail(email)
      if (birthday || age)
        setAge(age ? age : getAge(null, null, null, birthday))
      if (genderValue) setMainGender(parseInt(genderValue))
      if (lead?.benefitIds) {
        const storedIds = Array.isArray(lead.benefitIds)
          ? lead.benefitIds
          : lead.benefitIds.split(",")
        let filter = [...benefitsFilter]
        let allNotSelected = true
        filter.map(ben => {
          const isSelected = storedIds.includes(ben.id)
          ben.isSelected = isSelected
          allNotSelected = !isSelected
          return ben
        })
        if (allNotSelected)
          filter.map(ben => {
            ben.isSelected = ben.persist
            return ben
          })
        setBenefitsFilter(filter)
      }

      if (additionalMembers?.data) setAdditionInsurers(additionalMembers.data)
      if (companies?.data && companies.data.length) {
        const companyIds = companies?.data
        let filter = [...insuranceCompanyFilter]
        let allNotSelected = true
        filter.map(c => {
          const isSelected = companyIds.includes(parseInt(c.id))
          c.isSelected = isSelected
          allNotSelected = !isSelected
          return c
        })
        if (allNotSelected)
          filter.map(c => {
            c.isSelected = true
            return c
          })
        setInsuranceCompanyFilter(filter)
      }
      initEqtLogging(person.email)
    }
    setInitialized(true)

    // console.log("email: ", email)
    //clear search option before unload page
    window.addEventListener("beforeunload", handleUnload)
    return () => {
      window.removeEventListener("beforeunload", handleUnload)
    }
  }, [])

  const handleUnload = e => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("searchOptions")
    }
  }

  const updateSnackBar = msg => {
    setShowSnackBar(true)
    const warning = msg === "inpatient" ? uncheckInpatientWarning : msg
    setSnackBarMessage(warning)
  }
  return (
    <Layout
      props={pageContext.general}
      meta={meta}
      noindex={noMetaIndex}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      hideNewsletter={true}
      lang={lang}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className={`${style.quotationSearch} `}>
        <SnackBar
          show={showSnackBar}
          setShow={setShowSnackBar}
          message={snackBarMessage}
          timeout={2000}
        />
        {submitLoading && <Loading />}
        {detaliCard && (
          <div className={`${style.overlay}`}>
            <DetailsCard
              plan={detaliCard}
              updateDetailCard={updateDetailCard}
              proceedFurther={proceedFurther}
              labels={searchLabels}
              openCalendly={openCalendly}
              currency={currency}
              premiumDivideBy={premiumDivideBy}
            />
          </div>
        )}
        {openFilter && (
          <div className={`${style.overlay}`}>
            <MobileFilter
              lang={lang}
              general={pageContext.general}
              updateMobileFilter={updateMobileFilter}
              filterGetter={filterGetter}
              filterSetter={filterSetter}
              filterLabels={filterLabels}
              labels={searchLabels}
              // defaultOptions={defaultOptions}
              ageList={ageList}
              countryList={countryList}
              goToQuote={goToQuote}
              hideAgeFilter={hideAgeFilter.current}
              // cta={bookACallCta}
              updateSnackBar={updateSnackBar}
              // currency={currency}
              // currencyList={currencyList}
              // setCurrency={setCurrency}
            />
          </div>
        )}
        <div
          className={`container-fluid text-center ${style.header}  position-relative d-grid`}
          style={{ backgroundImage: `url(${glossaryHeaderBg})` }}
        >
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.leftEmpty} position-absolute`}
            src={leftEmpty}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.leftFull} position-absolute`}
            src={leftFull}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.right} position-absolute d-lg-block d-none`}
            src={shape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{headerTitle}</h1>
        </div>
        <div className={`${style.quotationBody} position-relative`}>
          <div
            className={`container ${style.content} card soft-corners soft-shadows mb-5`}
          >
            <div>
              <h2 className={`ming ${style.progressTitle}`}>
                {progressTitle.split(`\\n`).map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </h2>
              {step === 2 && searchSubTitle && (
                <p className={``}>{searchSubTitle}</p>
              )}
              <Dash align="left" colorClass="bg-teal d-lg-block d-none" />
            </div>
            {!initialized && <Skeleton />}
            {step === 1 && initialized && (
              <StepSearch
                // defaultOptions={defaultOptions}
                hideAgeFilter={hideAgeFilter.current}
                updateMobileFilter={updateMobileFilter}
                proceedFurther={proceedFurther}
                loading={loading}
                plansSearched={plansSearched}
                filterLabels={filterLabels}
                filterGetter={filterGetter}
                filterSetter={filterSetter}
                loadMorePlans={loadMorePlans}
                updateCompare={updateCompare}
                plansForComparing={plansForComparing}
                updateDetailCard={updateDetailCard}
                labels={searchLabels}
                openCalendly={openCalendly}
                currency={currency}
                currencyList={currencyList}
                setCurrency={setCurrency}
                countryList={countryList}
                goToQuote={goToQuote}
                updateSnackBar={updateSnackBar}
                ageList={ageList}
                lang={lang}
                submitEmail={submitEmail}
              />
            )}
            {step === 2 && (
              <QuotationApply
                plans={selectedPlans}
                form={form}
                isHorizontal={applyType === 0}
                isCompare={applyType === 2}
                updateStep={updateStep}
                location={location}
                submitForm={submitForm}
                labels={applyLabels}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setSubmitLoading={setSubmitLoading}
                currency={currency}
                premiumDivideBy={premiumDivideBy}
              />
            )}
            {step === 3 && (
              <StepThankyou subtitle={thankSubtitle} content={thankContent} />
            )}
          </div>

          {/* {((step === 2 && applyType !== 2) || step === 1) && (
            <Banner props={data.banner} lang={lang} />
          )} */}
          {step === 2 && applyType === 2 && (
            <ExplainSection
              context={{ title: explainTitle, content: explainContent }}
              bgColor={"bg-daffodil"}
            />
          )}
          {step === 3 && (
            <div className={`bg-ming`}>
              <Resources
                props={data.resources}
                categories={pageContext.categories}
                labels={keyLabels}
                lang={lang}
                bgColor={"bg-mint quotation"}
              />
            </div>
          )}

          {/* <div className={`overflow-hidden`}>
            <Pros
              props={generalData.sellingPoints}
              lang={lang}
              additionalClass={"nonHomepage"}
            />
            <Process
              props={generalData.process}
              lang={lang}
              additionalClass={"nonHomepage"}
            />
            <Partners props={homepageData.partners} lang={lang} />
            <Testimonial props={homepageData.testimony} lang={lang} />
          </div> */}
          <div className={`${style.toolBottomWrapper}`}>
            <ToolBottom item={toolBottomCta} lang={lang} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default QuotationSearchPage
