// extracted by mini-css-extract-plugin
export var alertRed = "ComparisonTable-module--alert-red--3fc79";
export var bgBarGrey = "ComparisonTable-module--bg-bar-grey--b7d63";
export var bgBeige = "ComparisonTable-module--bg-beige--c9297";
export var bgBeige2 = "ComparisonTable-module--bg-beige2--fdb81";
export var bgCopper = "ComparisonTable-module--bg-copper--6070c";
export var bgDaffodil = "ComparisonTable-module--bg-daffodil--9828b";
export var bgGreyText = "ComparisonTable-module--bg-grey-text--74767";
export var bgMing = "ComparisonTable-module--bg-ming--36cd0";
export var bgMint = "ComparisonTable-module--bg-mint--b2570";
export var bgRed = "ComparisonTable-module--bg-red--e5322";
export var bgRedTrans = "ComparisonTable-module--bg-red-trans--6cd60";
export var bgSand = "ComparisonTable-module--bg-sand--be1d8";
export var bgSoftSun = "ComparisonTable-module--bg-softSun--9790c";
export var bgTeal = "ComparisonTable-module--bg-teal--ae77c";
export var bgWhite = "ComparisonTable-module--bg-white--a252c";
export var bgWomenSharp = "ComparisonTable-module--bg-women-sharp--f47de";
export var blackText = "ComparisonTable-module--black-text--b5a3a";
export var body = "ComparisonTable-module--body--817ad";
export var borderMing = "ComparisonTable-module--border-ming--8d083";
export var cell = "ComparisonTable-module--cell--5fb48";
export var comparisonTable = "ComparisonTable-module--comparisonTable--3f46b";
export var copper = "ComparisonTable-module--copper--b304c";
export var corners = "ComparisonTable-module--corners--ff8c8";
export var cursorNormal = "ComparisonTable-module--cursor-normal--47676";
export var cursorPointer = "ComparisonTable-module--cursor-pointer--d98d0";
export var customMarkdown = "ComparisonTable-module--customMarkdown--95adb";
export var dUnset = "ComparisonTable-module--d-unset--d8b27";
export var darkGrey = "ComparisonTable-module--dark-grey--2f151";
export var dash = "ComparisonTable-module--dash--cf5b3";
export var dashBig = "ComparisonTable-module--dashBig--e39c9";
export var dataCol = "ComparisonTable-module--dataCol--a5b88";
export var deadSalmon = "ComparisonTable-module--dead-salmon--1b87a";
export var firstCol = "ComparisonTable-module--firstCol--97672";
export var flex = "ComparisonTable-module--flex--984f9";
export var flex1 = "ComparisonTable-module--flex-1--02a31";
export var fontSize12 = "ComparisonTable-module--font-size-12--23fa1";
export var fontSize20 = "ComparisonTable-module--font-size-20--21cbe";
export var greyPlaceholder = "ComparisonTable-module--grey-placeholder--590c1";
export var greyText = "ComparisonTable-module--grey-text--158fb";
export var h1sizing = "ComparisonTable-module--h1sizing--e97d9";
export var h2sizing = "ComparisonTable-module--h2sizing--d7ea3";
export var header = "ComparisonTable-module--header--592d7";
export var hidden = "ComparisonTable-module--hidden--b486c";
export var imageAni = "ComparisonTable-module--imageAni--1e27f";
export var imageZoom = "ComparisonTable-module--imageZoom--1bd66";
export var imageZoomGatsby = "ComparisonTable-module--imageZoomGatsby--3799e";
export var italic = "ComparisonTable-module--italic--4cc71";
export var lightGrey = "ComparisonTable-module--light-grey--6d7f5";
export var logo = "ComparisonTable-module--logo--45016";
export var lora = "ComparisonTable-module--lora--424d3";
export var loraBold = "ComparisonTable-module--lora-Bold--bf715";
export var loraBoldItalic = "ComparisonTable-module--lora-BoldItalic--cda7e";
export var loraMedium = "ComparisonTable-module--lora-Medium--6bbcc";
export var loraSemiBold = "ComparisonTable-module--lora-SemiBold--d454b";
export var main = "ComparisonTable-module--main--a63e9";
export var ming = "ComparisonTable-module--ming--9f943";
export var mingHover2 = "ComparisonTable-module--ming-hover-2--2a329";
export var modalOpen = "ComparisonTable-module--modal-open--039d6";
export var noUnderline = "ComparisonTable-module--no-underline--88973";
export var openSans = "ComparisonTable-module--openSans--2c458";
export var openSansBold = "ComparisonTable-module--openSans-Bold--71dda";
export var openSansSemiBold = "ComparisonTable-module--openSans-SemiBold--ee008";
export var pageGuide = "ComparisonTable-module--page-guide--bbaa8";
export var popUp = "ComparisonTable-module--popUp--60b55";
export var popUpAni = "ComparisonTable-module--pop-up-ani--b2f93";
export var price = "ComparisonTable-module--price--8c1b4";
export var quotation = "ComparisonTable-module--quotation--16fdd";
export var round = "ComparisonTable-module--round--c5d8a";
export var roundCorners = "ComparisonTable-module--round-corners--6c550";
export var softCorners = "ComparisonTable-module--soft-corners--bbd94";
export var softShadows = "ComparisonTable-module--soft-shadows--5d8ee";
export var softerCorners = "ComparisonTable-module--softer-corners--fa3f2";
export var softyCorners = "ComparisonTable-module--softy-corners--d4a58";
export var tableShadow = "ComparisonTable-module--table-shadow--42ddd";
export var teal = "ComparisonTable-module--teal--b0d1a";
export var title = "ComparisonTable-module--title--03056";
export var topNavShadow = "ComparisonTable-module--top-nav-shadow--c2f88";
export var topicArea = "ComparisonTable-module--topicArea--3f4a5";
export var uppercase = "ComparisonTable-module--uppercase--e5b3b";
export var wFull = "ComparisonTable-module--w-full--cb6da";
export var white = "ComparisonTable-module--white--20888";
export var womenRed = "ComparisonTable-module--women-red--307f9";
export var womenSharp = "ComparisonTable-module--women-sharp--3baa5";